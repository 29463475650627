<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Vị trí hàng hóa</span>
      <v-spacer></v-spacer>
      <v-btn small color="error" outlined @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text class="black--text">
      <v-row
        dense
        v-for="(item, index) in items"
        :key="`gl_${index}_${item.sku}`"
      >
        <v-col cols="3">SKU</v-col>
        <v-col cols="9">{{ item.sku }}</v-col>

        <v-col cols="3">Barcode</v-col>
        <v-col cols="9">{{ item.customer_goods_barcode }}</v-col>

        <v-col cols="3">Tên</v-col>
        <v-col cols="9">{{ item.name }}</v-col>

        <v-col cols="3">Vị trí</v-col>
        <v-col cols="9" class="error--text">{{ item.storage_location }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "GoodsLocationDialog",
  components: {},
  props: {
    sku: {
      type: [String, Number],
      default: () => 0,
    },
    idWarehouse: {
      type: [String, Number],
      default: () => 0,
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getItems() {
      const { data } = await httpClient.post("/goods/v1/report", {
        viewMode: "view0",
        id_warehouse: this.idWarehouse,
        sku: this.sku,
      });
      this.items = [...data.rows];
    },
  },
};
</script>

<style scoped></style>
